import React from "react";

import SecondaryLanding from "../components/layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import PersonalizationBar from "../components/notifications/personalization-message-bar";
import PersonalizationCards from "../components/card/personalization-cards";
import BestBanksDefault from "../components/best-banks/best-banks-default";

const SignOut = () => {
  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Sign Out"
    }
  ];

  const SEOData = {
    title: "You've been safely signed out",
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <PersonalizationBar />
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container text-center">
        <h1 id="sign-out-heading">You've been safely signed out.</h1>
        <div className="row justify-content-center">
          <div className="col-sm-8 col-md-5 col-lg-4">
            <a
              id="online-banking-sign-in"
              href="https://online.wafdbank.com"
              rel="noopener noreferrer"
              className="btn btn-access-green btn-block"
            >
              Sign In
            </a>
          </div>
        </div>
      </section>
      <PersonalizationCards containerClass="section-padding-adjusted bg-info" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default SignOut;
